import TagManager from "react-gtm-module";
import useModels from "../../../models";
import useUtils from "../utils";

const useDataLayers = () => {
  const { useSelectors } = useModels();
  const { useSelector, useCouponSelectors, useUtmSelectors } = useSelectors();
  const { brandSelector } = useCouponSelectors();
  const { utmSelector } = useUtmSelectors();
  const { eventCategory, eventAction } = useSelector(brandSelector);
  const { value: UTM } = useSelector(utmSelector);

  const { useGtm } = useUtils();
  const { getGtmArgs, getOnPremiseGtmArgs } = useGtm();

  const gtmValidateAgeGateYear = ({ year, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 2 : Age Gate - ${eventAction}`,
        null,
        year,
        brandName,
        UTM
      )
    );

  const gtmValidateAgeGateMoth = ({ month, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 2 : Age Gate - ${eventAction}`,
        null,
        month,
        brandName,
        UTM
      )
    );

  const gtmValidateAgeGateDay = ({ day, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 2 : Age Gate - ${eventAction}`,
        null,
        day,
        brandName,
        UTM
      )
    );

  const gtmValidateRegister = ({ UserID, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 3 : Registro - ${eventAction}`,
        null,
        null,
        brandName,
        UTM,
        { UserID }
      )
    );

  const gtmCouponSuccess = ({ couponCode, brandName, UserID }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 4 : Código QR - ${eventAction}`,
        couponCode,
        null,
        brandName,
        UTM,
        { UserID }
      )
    );

  const gtmEndExperience = ({ couponCode, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 4 : Botón Terminar - ${eventAction}`,
        couponCode,
        null,
        brandName,
        null
      )
    );

  const gtmFindShops = ({ couponCode }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 4 : Buscar Tiendas - ${eventAction}`,
        couponCode,
        null,
        null,
        null
      )
    );

  const gtmTakePart = ({ couponCode, brandName }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 5 : Terminar Proceso - ${eventAction}`,
        couponCode,
        null,
        brandName,
        null
      )
    );

  const gtmShopSelected = ({ couponCode, shopID }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 6 : Búsqueda de Tiendas - ${eventAction}`,
        couponCode,
        shopID,
        null,
        null
      )
    );

  const gtmGoToShop = ({ couponCode }) =>
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 6 : Ir a la Tienda - ${eventAction}`,
        couponCode,
        null,
        null,
        null
      )
    );

  const gtmCouponRedeemed = ({ couponCode, brandName, userID }) => {
    TagManager.dataLayer(
      getGtmArgs(
        "trackEvent",
        eventCategory,
        `Paso 4: Ver cupón SMS - ${eventAction}`,
        couponCode,
        null,
        brandName,
        UTM,
        { userID }
      )
    );
  };

  // New Gtms 2022

  const gtmValidateAgeGateCompleted = ({ eventLabel, brandName, interaction }) => {
    TagManager.dataLayer(
      getGtmArgs("GAEvent", "Age Gate", `Interaction`, eventLabel, "", brandName, UTM, {
        interaction,
      })
    );
  };

  const gtmValidateRegisterInteraction = ({ eventAction, tech, brandName }) =>
    TagManager.dataLayer(getGtmArgs("GAEvent", "Register", eventAction, tech, "", brandName, UTM));

  const gtmValidateFormInteraction = ({ eventAction, tech, brandName }) =>
    TagManager.dataLayer(getGtmArgs("GAEvent", "Form", eventAction, tech, "", brandName, UTM));

  const gtmCouponSuccessQr = ({
    status,
    brandName,
    userID,
    campaignDescription,
    qrCode,
    mlVolumePerUnit,
    totalUnits,
    freeUnits,
    pricePerUnit,
    currency,
    city,
    beesPoint,
  }) =>
    TagManager.dataLayer(
      getGtmArgs("GAEvent", "QR Code", "Generated", status, "", brandName, UTM, {
        userID,
        campaign_description: campaignDescription,
        QRCode: qrCode,
        ml_volume_per_unit: mlVolumePerUnit,
        total_units: totalUnits,
        free_units: freeUnits,
        price_per_unit: pricePerUnit,
        currency: currency,
        city: city,
        bees_point: beesPoint,
      })
    );

  const gtmCouponRedemptionSuccess = ({
    status,
    brandName,
    userID,
    campaignDescription,
    qrCode,
    mlVolumePerUnit,
    totalUnits,
    freeUnits,
    pricePerUnit,
    currency,
    city,
    pocNumber,
    pocName,
    beesPoint,
  }) =>
    TagManager.dataLayer(
      getGtmArgs("GAEvent", "QR Code", "Read", status, "", brandName, UTM, {
        userID,
        campaign_description: campaignDescription,
        QRCode: qrCode,
        ml_volume_per_unit: mlVolumePerUnit,
        total_units: totalUnits,
        free_units: freeUnits,
        price_per_unit: pricePerUnit,
        currency: currency,
        city: city,
        POC_number: pocNumber,
        POC_name: pocName,
        bees_point: beesPoint,
      })
    );

  const gtmOnPremiseAgeGate = ({ interaction }) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Age Gate", "Intercation", "Send", {
        interaction: interaction,
        component_name: "",
        element_text: "",
      })
    );

  const gtmOnPremiseAgeGateRemember = ({ interaction }) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Age Gate", "Intercation", "Recuerdame", {
        interaction: interaction,
        component_name: "",
        element_text: "",
      })
    );

  const gtmOnPremiseFormFocus = (interaction, step, stepName, button) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Form", step, stepName, {
        interaction: interaction,
        component_name: button,
      })
    );

  const gtmOnPremiseSuccesForm = (userID, interaction, button) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Form", "success", "", {
        interaction: interaction,
        component_name: button,
        userUid: userID,
      })
    );

  const gtmOnPremiseErrorForm = (interaction, errorDescription, button) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Form", "Error", errorDescription, {
        interaction: interaction,
        component_name: button,
      })
    );

  const gtmOnPremiseCodeForm = (code, interaction, button, elementText, userID) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Content", "Validar", code, {
        interaction: interaction,
        component_name: button,
        element_text: elementText,
        userUid: userID,
      })
    );

  const gtmOnPremiseCode = (code, interaction, button, elementText, userID) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Content", "Codigo de Cupon", code, {
        interaction: interaction,
        component_name: button,
        element_text: elementText,
        userUid: userID,
      })
    );

  const gtmOnPremiseCodeExpired = (code, interaction, button, elementText, userID) =>
    TagManager.dataLayer(
      getOnPremiseGtmArgs("GAEvent", "Content", "Codigo de Cupon", code, {
        interaction: interaction,
        component_name: button,
        element_text: elementText,
        userUid: userID,
      })
    );

  return {
    gtmValidateAgeGateYear,
    gtmValidateAgeGateMoth,
    gtmValidateAgeGateDay,
    gtmValidateRegister,
    gtmCouponSuccess,
    gtmEndExperience,
    gtmFindShops,
    gtmTakePart,
    gtmShopSelected,
    gtmGoToShop,
    gtmCouponRedeemed,

    gtmValidateAgeGateCompleted,
    gtmValidateRegisterInteraction,
    gtmValidateFormInteraction,
    gtmCouponSuccessQr,
    gtmCouponRedemptionSuccess,

    gtmOnPremiseAgeGate,
    gtmOnPremiseAgeGateRemember,
    gtmOnPremiseFormFocus,
    gtmOnPremiseSuccesForm,
    gtmOnPremiseErrorForm,
    gtmOnPremiseCodeForm,
    gtmOnPremiseCode,
    gtmOnPremiseCodeExpired,
  };
};

export default useDataLayers;
